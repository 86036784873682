import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"
import Card from "../components/card"
import CardNodo from "../components/cardNodo"
import BlueSpan from "../components/blueSpan"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAnchor } from "@fortawesome/free-solid-svg-icons"

const nodi = [
  {
    title: "Nodo Parlato Semplice",
    filename: "nodi-marinari-parlato-semplice.jpg",
    link: "nodi-marinari-di-avvolgimento",
    description:
      "Il nodo parlato è ii nodo d’avvolgimento molto facile da eseguire e molto utilizzato in diversi campi.",
  },
  {
    title: "Nodo Collo Scorrevole",
    filename: "nodi-marinari-collo-scorrevole.jpg",
    link: "nodi-marinari-di-avvolgimento",
    description:
      "Molto simile al parlato doppio che spesso viene identificato con una sua malriuscita esecuzione.",
  },
  {
    title: "Nodo Bocca Di Lupo",
    filename: "nodi-marinari-bocca-di-lupo.jpg",
    link: "nodi-marinari-di-avvolgimento",
    description:
      "il nodo bocca di lupo è un nodo molto conosciuto e di facile esecuzione.Viene di solito usato fissato ad un anello o a un palo.",
  },
  {
    title: "Nodo di Coltellaccio",
    filename: "nodi-marinari-di-coltellaccio.jpg",
    link: "nodi-marinari-di-avvolgimento",
    description:
      "Nodo di avvolgimento molto stabile e sicuro , usato principalmente in campo velico.",
  },
  {
    title: "Nodo di Coltellaccio Doppio",
    filename: "nodi-marinari-di-coltellaccio-doppio.jpg",
    link: "nodi-marinari-di-avvolgimento",
    description: "Una volta in più rispetto al nodo coltellaccio semplice.",
  },
  {
    title: "Nodo di Parlato Doppio",
    filename: "nodi-marinari-parlato-doppio.jpg",
    link: "nodi-marinari-di-avvolgimento",
    description:
      "Una volta in più rispetto al parlato semplice, con evidente aumento della stabilità del nodo.",
  },
  {
    title: "Nodo due volte e due mezzi colli",
    filename: "nodi-marinari-due-volte-e-due-mezzi-colli.jpg",
    link: "nodi-marinari-di-avvolgimento",
    description:
      "Nodo veloce ed affidabile, sotto tensione stringe sui colli e non sulle volte, si soglie facilmente.",
  },
  {
    title: "Nodo di giara",
    filename: "nodi-marinari-di-giara.jpg",
    link: "nodi-marinari-di-avvolgimento",
    description:
      "Fa parte dei nodi definiti margherite, ma non serve a ridurne la lunghezza, ma per serrare un corpo infilato nella sua zona centrale.",
  },
]

const DiAvvolgimento = () => (
  <Layout>
    <SEO title="Nodi Marinari di Avvolgimento" />
    <h1 className="text-center text-blue-500 font-bold text-3xl mt-12">
      <FontAwesomeIcon
        className="text-blue-600 stroke-current text-black"
        size="lg"
        icon={faAnchor}
      />{" "}
      I nodi marinari d'avvolgimento{" "}
      <FontAwesomeIcon
        className="text-blue-600 stroke-current text-black"
        size="lg"
        icon={faAnchor}
      />
    </h1>
    <div className="container mx-auto">
      <div className="font-serif shadow p-5 text-justify italic text-xl mt-5">
        Appartengono alla famiglia di{" "}
        <BlueSpan>nodi marinari di avvolgimento</BlueSpan> famiglia tutti quei
        nodi che si ottengono avvolgendo in modo opportuno una cima intorno a un
        qualsiasi oggetto e possono essere impiegati per vincolare un cavo di
        sostegno – per sospendere un oggetto – per unire insieme vari oggetti.{" "}
        <br />
        Esistono diverse tipologie di nodi di avvolgimento tra cui:
        <br />
        {nodi.map(nodo => (
          <CardNodo
            filename={nodo.filename}
            nomeNodo={nodo.title}
            description={nodo.description}
          ></CardNodo>
        ))}
      </div>
      <Card className="mt-5"></Card>
    </div>
  </Layout>
)

export default DiAvvolgimento
